import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

interface NotificationData {
  message: string;
  type?: 'success';
  desc?: string;
  onOK?: () => void;
}

export const useNotificationStore = defineStore('notification', () => {
  const data = ref<NotificationData | null>(null);

  const setData = (value: NotificationData) => (data.value = value);

  const show = (
    message: NotificationData['message'],
    options?: Partial<NotificationData>
  ) => {
    setData({ message, ...options });
  };

  const reset = () => (data.value = null);

  const success = (
    message: NotificationData['message'],
    options?: Partial<NotificationData>
  ) => {
    show(message, { type: 'success', ...options });
    setTimeout(() => {
      reset();
    }, 3000);
  };

  const requestError = (
    error: AxiosError,
    options?: Partial<NotificationData>
  ) => {
    const message = get(error, 'response.data.error.message') || error.message;

    show(message, options);
    console.warn(error);
  };

  const requestSuccess = (
    response: AxiosResponse,
    options?: Partial<NotificationData>
  ) => {
    const message = get(response, 'data.data.message');
    success(message, options);
  };

  return { data, show, success, reset, requestError, requestSuccess };
});
